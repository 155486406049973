import { fetchMyInfo, requestMemberStatus } from '@/api/member';
import { requestLogin, requestLogout, requestLoginForLegacy, requestLogoutForLegacy } from '@/api/auth';
import { requestTermAgree } from '@/api/common';
import VueCookies from 'vue-cookies';
import { isDocpleNative, callDocpleNativeLogout } from '@/lib/Native';

const user = {
  state: {
    // 관리자 : authorityId === 'SYSTEM';
    // 매니저 : authorityId === 'MANAGE';
    authorityId: '',
    emailDomain: '',
    emailId: '',
    joinType: '',
    name: '',
    sex: '',
    uid: '',
    writeName: '',
    waterMarkUrl: '',
    gaTrackingId: '',
    myCash: 0,
    recvCnt: 0,
    phone: '',
    event: false,
    hospitalInfo: {
      receptionCount: 0, // 접수 대기 카운트
      treatmentCount: 0, // 진료 대기 카운트
      visitReservationCount: 0, // 방문예약 카운트
      receiptAccepting: true, // 현재 진료 중 상태값
      totalCount: 0,
      lastMessageSendTime: '',
      hospitalName: '', // 병원 이름
      doctorName: '', // 의사 이름
      hospitalId: 0, // 병원 아이디
      iframeId: '',
    },

    // ci 본인인증을 위한 상태
    accessToken: '',
    isCert: null,
  },
  getters: {
    userInfo: state => ({
      authorityId: state.authorityId,
      emailDomain: state.emailDomain,
      emailId: state.emailId,
      joinType: state.joinType,
      name: state.name,
      sex: state.sex,
      uid: state.uid,
      writeName: state.writeName,
      phone: state.phone,
      useYN: state.useYN,
    }),
    userId: state => state.uid,
    gaTrackingId: state => state.gaTrackingId,
    waterMark: state => state.waterMarkUrl,
    writeName: state => state.writeName,
    userPhone: state => state.phone,
    recvCnt: state => state.recvCnt,
    myCash: state => state.myCash,
    isMangerOrHigher: state => ['SYSTEM', 'MANAGE'].includes(state.authorityId),
    isSystem: state => ['SYSTEM'].includes(state.authorityId),
    isLimitedWriting: state => state.useYN === 'L',
    isDoctor: state => state.joinType === '1',
    isCompany: state => state.joinType === '2',
    userType: (_, getters) => {
      if (getters.isDoctor) return 'doctor';
      if (getters.isDoctor) return 'company';
    },
    hospitalInfo: state => state.hospitalInfo,
    accessToken: state => state.accessToken,
    isCert: state => state.isCert,
  },
  mutations: {
    logout(state) {
      state.authorityId = null;
      state.emailDomain = null;
      state.emailId = null;
      state.joinType = null;
      state.name = null;
      state.sex = null;
      state.uid = null;
      state.writeName = null;
      state.waterMarkUrl = null;
      state.gaTrackingId = null;
      state.myCash = 0;
      state.recvCnt = 0;
      state.useYN = null;
    },
    setUserInfo(state, payload) {
      const { gaTrackingId, waterMarkUrl, authorityId, emailDomain, emailId, joinType, name, sex, uid, writeName, myCash, recvCnt, mobile1, mobile2, mobile3 } = payload;
      state.authorityId = authorityId;
      state.emailDomain = emailDomain;
      state.emailId = emailId;
      state.joinType = joinType;
      state.name = name;
      state.sex = sex;
      state.uid = uid;
      state.writeName = writeName;
      state.waterMarkUrl = waterMarkUrl;
      state.gaTrackingId = gaTrackingId;
      state.myCash = myCash;
      state.recvCnt = recvCnt;
      state.phone = `${mobile1}${mobile2}${mobile3}`;
      state.useYN = payload.useYN;
    },
    setUid(state, payload) {
      state.uid = payload;
    },
    setEvent(state, payload) {
      state.event = payload.event;
    },
    setHospital(state, payload) {
      state.hospitalInfo = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setIsCertificate(state, payload) {
      state.isCert = payload;
    },
  },
  actions: {
    // 로그인
    async login({ commit, rootState: { isMobile } }, payload) {
      commit('isPending', true, { root: true });

      try {
        const response = await requestLogin(payload);

        if (response.data.resultCode === '0') {
          const { accessToken, refreshToken, loginOTP: otp, event } = response.data.result;
          const statusResponse = await requestMemberStatus({ uid: payload.id });
          if (!statusResponse?.data?.body?.certificate) {
            commit('setAccessToken', accessToken);
            commit('setIsCertificate', statusResponse?.data?.body?.certificate);
            return;
          }
          if (process.env.NODE_ENV !== 'local') {
            await requestLoginForLegacy({ otp });
          }
          VueCookies.set('accessToken', accessToken, '30MIN');
          // 로그인 유지 시 10일 저장, 아닐시 세션
          // 210910 놀다가 로그아웃 되는 이슈로 인한 4h -> 8h
          VueCookies.set('refreshToken', refreshToken, payload.loginKeep === 'Y' ? '10d' : '8h');
          // 로그인 유지 여부 쿠키
          if (payload.loginKeep === 'Y') VueCookies.set('dpLoginKeep', 'Y', '10d');
          const nowDay = new Date();

          // 마케팅 동의를 본적없는 회원들에게 띄우는 팝업
          if (statusResponse?.data?.body?.terms && Array.isArray(statusResponse?.data?.body?.terms)) {
            const marketingTerm = statusResponse?.data?.body?.terms.find(item => item.termsKindCode === '60000');
            if (!marketingTerm?.agreeDate) {
              if (confirm('의료계 소식을 빠르게 받아보세요!\n학술대회, 세미나, 연수강좌 등 유용한\n정보를 보내드립니다.')) {
                const marketingResponse = await requestTermAgree({
                  termsVersion: marketingTerm.termsVersion,
                  termsKindCode: marketingTerm.termsKindCode,
                  agree: true,
                });
                marketingResponse?.data?.body === 'ok' && alert(`${nowDay.getFullYear()}년 ${nowDay.getMonth() + 1}월 ${nowDay.getDate()}일 마케팅 수신 동의하였습니다.`);
              } else {
                const marketingResponse = await requestTermAgree({
                  termsVersion: marketingTerm.termsVersion,
                  termsKindCode: marketingTerm.termsKindCode,
                  agree: false,
                });
                marketingResponse?.data?.body === 'ok' && alert(`${nowDay.getFullYear()}년 ${nowDay.getMonth() + 1}월 ${nowDay.getDate()}일 마케팅 수신 거부하였습니다.`);
              }
            }
          }
          commit('isLoggedIn', true, { root: true });
          commit('setEvent', { event: event === '1' });
        } else {
          switch (response.data.resultCode) {
            case '200':
            case '201':
            case '202':
              alert('아이디와 비밀번호를 확인해주세요.');
              break;
            case '300':
              alert('사용 정지된 회원입니다.');
              if (isMobile) {
                location.href = '/login';
              } else {
                location.href = '/';
              }
              break;
            case '301':
              alert('토큰 생성을 실패했습니다. 다시 시도해주세요.');
              break;
            case '302':
              alert('현재 가입승인 절차가 진행 중입니다.');
              if (isMobile) {
                location.href = '/login';
              } else {
                location.href = '/';
              }
              break;
            case '303':
              /**
               * @author Ash
               * @date 2024.04.12
               * @description 통합거절시 나오는 문구인데 한가지 케이스(면허관련)으로만 처리되어 추후 롤백될 수 있음, 한달뒤에도 유지시 주석 제거
               */
              // alert('가입승인이 거절되었습니다. 올바른 회원정보로 다시 가입 부탁드립니다.');
              alert('의사면허번호와 의사면허증이 일치하지 않습니다. 고객센터(1644-4702)로 문의 바랍니다.');
              break;
          }
        }
      } catch (e) {
        throw Error(e);
      } finally {
        commit('isPending', false, { root: true });
      }
    },
    // 로그아웃
    /**
     * @author Ash
     * @date 2024.03.13
     * @description 진료실 오픈시 disconnect 관련 주석 제거 및 하단 구조분해할당에서 state 추가, 관련 n12, n14에서도 작업 필요
     */
    async logout({ commit, dispatch, rootState: { isMobile } }) {
      commit('isPending', true, { root: true });
      // 모바일, PC 로그인 유지 분리를 위한 로그아웃 api 제외
      try {
        // const { joinType } = state;
        dispatch('setHospitalInfo', null);
        commit('resetState', null, { root: true });
        commit('logout');
        // if (joinType === '1') {
        //   await hospitalLogout();
        // }
        if (process.env.NODE_ENV !== 'local') {
          await requestLogoutForLegacy();
        }
        await requestLogout();
      } catch (e) {
        throw Error(e);
      } finally {
        VueCookies.remove('accessToken');
        VueCookies.remove('refreshToken');
        VueCookies.remove('dpLoginKeep');
        VueCookies.remove('communityToken');
        if (isDocpleNative()) {
          await callDocpleNativeLogout();
        }
        commit('isPending', false, { root: true });
        if (isMobile) {
          location.href = '/login';
        } else {
          location.href = '/';
        }
      }
    },
    // 유저정보
    async getUserInfo({ commit }) {
      commit('isPending', true, { root: true });
      try {
        const response = await fetchMyInfo({});
        if (response?.data.resultCode === '0') {
          commit('setUserInfo', response.data.result);
          commit('setEvent', { event: response.data.result?.event === '1' });
        }
        commit('isPending', false, { root: true });
      } catch (e) {
        throw Error(e);
      } finally {
        commit('isPending', false, { root: true });
      }
    },
    setHospitalInfo({ commit }, payload) {
      commit(
        'setHospital',
        payload || {
          receptionCount: 0, // 접수 대기 카운트
          treatmentCount: 0, // 진료 대기 카운트
          visitReservationCount: 0, // 방문예약 카운트
          receiptAccepting: true, // 현재 진료 중 상태값
          totalCount: 0,
          lastMessageSendTime: '',
          hospitalName: '', // 병원 이름
          doctorName: '', // 의사 이름
          hospitalId: 0, // 병원 아이디
          iframeId: '',
        },
      );
    },
  },
};

export default user;
