<template>
  <div id="app" :class="{ 'pc-bg-color': !isMobile }">
    <router-view />
    <!-- <iframe v-if="!isMobile && !this.$route.path.includes('/hospital')" :src="hospitalUrl" id="ollacare-hospital" class="hidden-iframe" /> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VueCookies from 'vue-cookies';
import postMessageController from '@/lib/OllacareHospital';

export default {
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      hospitalInfo: 'hospitalInfo',
      userInfo: 'userInfo',
    }),
    postMessage() {
      return postMessageController();
    },
    hospitalUrl() {
      if (location?.origin.includes('localhost') || location?.origin.includes('dev.docple.com')) {
        return 'https://dev.hospital.ollacare.com';
      }
      if (location?.origin.includes('stage.docple.com')) {
        return 'https://stage.hospital.ollacare.com';
      }
      if (location?.origin.includes('docple.com')) {
        return 'https://hospital.ollacare.com';
      }

      return 'https://hospital.ollacare.com';
    },
  },
  created() {
    // iOS Header, Footer issue
    const doc = document.documentElement;
    doc.style.setProperty('--vh', `${window.innerHeight}px`);

    window.addEventListener('resize', () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight}px`);
    });
    this.loadBlock(window.localStorage.getItem('block'));
  },
  mounted() {
    this.postMessage.addPostMessageEventListener(this.handlePostMessageEvent);
  },
  beforeDestroy() {
    this.postMessage.removePostMessageEventListener(this.handlePostMessageEvent);
  },
  methods: {
    ...mapMutations(['loadBlock']),
    /**
     * @author Ash
     * @date 2024.03.11
     * @param {{eventName: string, data: any, actionTime: string}} event 수신 데이터
     * @description iframe과 통합 데이터 전송 수단, eventName으로 분기처리 필요
     */
    handlePostMessageEvent(event) {
      const { origin, data } = event;
      if (origin.includes('hospital.ollacare.com')) {
        this.hospitalPostMessage(data);
        return;
      }
      if (origin.includes('docple.com')) {
        this.insightPostMessage(data);
        return;
      }
    },
    hospitalPostMessage(data) {
      if (data?.eventName === 'hospitalInfo') {
        this.$store.dispatch('setHospitalInfo', data?.data);
        return;
      }
      if (data?.eventName === 'logout') {
        this.$store.dispatch('setHospitalInfo', null);
        VueCookies.set(
          'dailyHospitalAlert',
          'N',
          this.$moment(
            this.$moment()
              .add(1, 'days')
              .format('YYYY-MM-DD'),
          ).diff(this.$moment(), 'seconds'),
        );
      }
    },
    insightPostMessage(data) {
      /**
       * @author Ash
       * @description 인사이트 라우팅, 호출시 의사회원 체크
       */
      if (data?.eventName === 'insightRedirect' && data?.data?.redirectUrl) {
        if (this.userInfo.joinType !== '1') {
          alert(`의사회원만 이용할 수 있는 메뉴입니다.`);
          return;
        }
        location.href = data.data.redirectUrl;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hidden-iframe {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
}
</style>
