// 공통 API
// Create
// Fetch (Read)
// Update
// Delete
import axios from 'axios';
import { get, post, postV3, store } from '..';
import { POLICY_TYPE, getPolicy, getPolicyList } from './policy';

const MAX_SIZE_LIMIT_NGINX = 35 * 1024 * 1024;

// 파일 업로드 ( file List , url : contextPath, type  )
// type : 이미지 = img , 첨부파일 = file
const uploadFiles = async (fileList, url, type = 'img') => {
  // input formdata
  store.commit('isPending', true);
  const splitBucket = await fileList.reduce(
    (acc, file) => {
      const bucket = acc[acc.length - 1];
      if (file.size > MAX_SIZE_LIMIT_NGINX) {
        // console.log(file);
        return acc;
      }
      if (bucket.size + file.size > MAX_SIZE_LIMIT_NGINX) {
        acc.push({ size: file.size, files: [file] });
        return acc;
      }
      bucket.size += file.size;
      bucket.files.push(file);
      return acc;
    },
    [{ size: 0, files: [] }],
  );

  const formDatas = await splitBucket.map(bucket => {
    const formData = new FormData();
    formData.append('contextPath', url);
    return bucket.files.reduce((formData, file) => {
      formData.append(`${type}[]`, file);
      return formData;
    }, formData);
  });

  const ret = await Promise.all(
    formDatas.map(formData => {
      return axios.post('/api/file/uploadMultipleFiles', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    }),
  ).then(returns => {
    return returns.reduce((acc, ret) => {
      if (acc === undefined) {
        return ret;
      }
      acc.result = [...acc.data.result, ...ret.data.result];
      return acc;
    }, undefined);
  });
  store.commit('isPending', false);
  return ret;
};

const requestAuthCommunity = payload => post('/auth/communityLogin', payload);

// ----------- 스크랩 ----------- //
// 스크랩
const requestBoardScrap = payload => post('/scrap/add', payload);

// 스크랩 폴더 목록
const fetchScrapFolderList = payload => post('/scrap/folder/list', payload);

// 스크랩 폴더 추가
const createScrapFolder = payload => post('/scrap/folder/add', payload);

// 스크랩 폴더명 수정
const updateScrapFolderName = payload => post('/scrap/folder/name/update', payload);

// 스크랩 폴더 삭제
const deleteScrapFolder = payload => post('/scrap/folder/delete', payload);

// 스크랩 폴더  이동
const moveScrapFolder = payload => post('/scrap-folder', payload);

// 약관동의
const requestTermsAgree = payload => postV3('/terms/agree-multiple', payload);

// 약관동의
const requestTermAgree = payload => postV3('/terms/agree', payload);

/**
 * @author Ash
 * @name requestTermsList
 * @date 2024.03.26
 * @returns { id, documentTypeCode, documentTypeName, termsKindCode, termsKindName, termsVersion, startDate, endDate, title, required, content }
 * @description 최신약관 목록조회
 */
const requestTerms = () => get('/api/v3/terms');

/**
 * @author Ash
 * @name requestTermsCode
 * @date 2024.04.11
 * @param string
 * @returns { id, documentTypeCode, documentTypeName, termsKindCode, termsKindName, termsVersion, startDate, endDate, title, required, content }
 * @description 특정 약관의 목록조회(list)
 */
const requestTermsCode = payload => get(`/api/v3/terms/${payload}`);

const getBanner = async key => {
  try {
    return get(`/api/v2/dadp/banners/${key}`);
  } catch (e) {
    return {
      status: e.response.status ?? 500,
      data: [],
    };
  }
};
// ----------- 페이지 로그 ----------- //
const requestPageLog = payload => post('/log/pageLogInsert', payload);
// ----------- // 페이지 로그 ----------- //

const postBannerLog = ({ eventType, adId }) => post('/v2/dadp/banners/logs', { eventType, adId });

const getRecommenderNameById = payload => post('/common/getRecommenderName', payload);

export {
  uploadFiles,
  requestAuthCommunity,
  requestBoardScrap,
  fetchScrapFolderList,
  createScrapFolder,
  updateScrapFolderName,
  deleteScrapFolder,
  moveScrapFolder,
  requestTermsAgree,
  requestTermAgree,
  requestTerms,
  requestTermsCode,
  getBanner,
  requestPageLog,
  postBannerLog,
  getPolicyList,
  getPolicy,
  getRecommenderNameById,
  POLICY_TYPE,
};
